<template>
  <perseu-card :title="titleDialog">
    <template slot="content">
      <v-form ref="form" @submit.prevent="submitForm">
        <v-text-field
          v-model.trim="obj.name"
          label="Nome"
          :rules="[$rules.required]"
        />
      </v-form>
    </template>
    <template slot="actions">
      <v-btn color="secondary" @click="submitForm">Salvar</v-btn>
    </template>
  </perseu-card>
</template>

<script>
import { save } from "@/services/pathogens-service";
export default {
  props: {
    pathogen: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    obj: {},
  }),
  computed: {
    titleDialog() {
      return (this.obj.id ? "Edição de" : "Novo") + " Patógeno";
    },
  },
  mounted() {
    this.obj = this.pathogen || {};
  },
  methods: {
    async submitForm() {
      if (!this.$refs.form.validate()) return;
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await save(this.obj);
        if (this.obj.id) {
          this.$emit("update", data);
        } else {
          this.$emit("create", data);
        }
        this.obj = {};
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
